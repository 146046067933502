<template>
  <div class="h-100 w-100 d-flex justify-content-center align-items-center">
    <b-card class="px-3 py-2">
      <h3 class="text-center">Вход</h3>
      <form class="mt-3" @submit.prevent="onSubmit">
        <b-form-input v-model="email" required type="email" placeholder="email" />
        <b-form-input
          v-model="password"
          required
          type="password"
          class="mt-2"
          placeholder="пароль"
        />
        <b-button type="submit" class="w-100 mt-2" variant="primary" @click.prevent="onSubmit">
          Войти
        </b-button>
      </form>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'AuthLayout',
  data() {
    return {
      email: process.env.VUE_APP_LOGIN || '',
      password: process.env.VUE_APP_PASSWORD || '',
    };
  },
  methods: {
    ...mapActions('auth', ['ActionLogin']),
    async onSubmit() {
      try {
        await this.ActionLogin(this.$data);
        await this.$router.push({ name: 'Admin' });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
  },
});
</script>

<style lang="scss" scoped></style>
